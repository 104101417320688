const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/login/passwordReset.vue'
      ),
  },
  {
    path: '/googleAuth',
    name: 'GoogleAuth',
    component: () =>
      import(
        /* webpackChunkName: "googleAuth" */ '../views/login/googleAuth.vue'
      ),
  },
  {
    path: '/',
    name: 'AddressPool',
    component: () =>
      import(
        /* webpackChunkName: "addressPool" */ '../views/addressPool/index.vue'
      ),
  },
  {
    path: '/address/detail/:id',
    name: 'AddressPoolDetail',
    component: () =>
      import(
        /* webpackChunkName: "addressPoolDetail" */ '../views/addressPool/detail.vue'
      ),
  },
  {
    path: '/authorization',
    name: 'Authorization',
    component: () =>
      import(
        /* webpackChunkName: "authorization" */ '../views/authorization/index.vue'
      ),
  },
  {
    path: '/payout',
    name: 'Payout',
    component: () =>
      import(/* webpackChunkName: "payout" */ '../views/payout/index.vue'),
  },
  {
    path: '/payout/detail/:id',
    name: 'PayoutDetail',
    component: () =>
      import(
        /* webpackChunkName: "payoutDetail" */ '../views/payout/detail.vue'
      ),
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () =>
      import(
        /* webpackChunkName: "withdrawal" */ '../views/withdrawal/index.vue'
      ),
  },
  {
    path: '/withdrawal/detail/:id',
    name: 'WithdrawalDetail',
    component: () =>
      import(
        /* webpackChunkName: "withdrawalDetail" */ '../views/withdrawal/detail.vue'
      ),
  },
  {
    path: '/account',
    name: 'Account',
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/account/index.vue'),
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: () =>
      import(/* webpackChunkName: "merchant" */ '../views/merchant/index.vue'),
  },
  {
    path: '/merchant/detail/:id',
    name: 'MerchantDetail',
    component: () =>
      import(
        /* webpackChunkName: "merchantDetail" */ '../views/merchant/detail.vue'
      ),
  },
]
export default routes
