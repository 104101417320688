import request from '@/utils/request'

/* 登录 */
export function login(data) {
  return request({
    url: '/admin/pla/admin/login',
    method: 'post',
    data,
  })
}

/* 获取登录用户信息 */
export function getLoginInfo(data) {
  return request({
    url: '/admin/pla/admin/get/login/info',
    method: 'post',
    data,
  })
}

/* 列表 */
export function getAccountPage(data) {
  return request({
    url: '/admin/pla/admin/page',
    method: 'post',
    data,
  })
}

/* 新增 */
export function addAccount(data) {
  return request({
    url: '/admin/pla/admin/add',
    method: 'post',
    data,
  })
}

/* 更新 */
export function updateAccount(data) {
  return request({
    url: '/admin/pla/admin/update',
    method: 'post',
    data,
  })
}

/* 设置密码 */
export function updatePassword(data) {
  return request({
    url: '/admin/pla/admin/modify/pwd',
    method: 'post',
    data,
  })
}

/* 重置密码 */
export function resetPassword(data) {
  return request({
    url: '/admin/pla/admin/reset/pwd',
    method: 'post',
    data,
  })
}

/* 获取验证码信息 */
export function getGoogleAuth(data) {
  return request({
    url: '/admin/pla/admin/getGoogleAuth',
    method: 'post',
    data,
  })
}

/* 绑定验证码 */
export function bindGoogleAuth(data) {
  return request({
    url: '/admin/pla/admin/bindGoogleAuth',
    method: 'post',
    data,
  })
}

// 收款账户资金列表
export function acountBalanceList(data) {
  return request({
    url: '/account/pla/account/find/list',
    method: 'post',
    data,
  })
}
