import { ElMessage } from 'element-plus'
export default class CheckAPI {
  constructor(data) {
    Object.assign(this, data)
  }

  // 检查接口是否正常
  check() {
    // return true
    return this.success
  }

  // 检查接口是否异常
  invalid() {
    return !this.success
  }

  // 获取错误信息
  errorMsg() {
    const msg = this.message
    return msg
  }

  // toast提示错误信息
  toast() {
    const message = this.errorMsg()
    ElMessage({
      message,
      type: 'error',
    })
  }
}
