import request from '@/utils/request'

/* 获取所有网络配置 */
export function getAllNetworks(data) {
  return request({
    url: '/admin/pla/config/allNetworks',
    method: 'get',
    params: data,
  })
}

/* 获取币种配置 */
export function getSymbolConfig(data) {
  return request({
    url: '/admin/pla/config/symbolList',
    method: 'get',
    params: data,
  })
}
