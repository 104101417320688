import BigNumber from 'bignumber.js'

/** blockATM官网链接 */
export const OFFICIAL_WEBSITE = 'https://blockatm.net'

export const tronApikey = '38ba5ffa-599c-4fa4-91f7-846be232f434'

// 最大授权值
export const MAX_TOKEN_ALLOWANCE_AMOUNT = new BigNumber(2)
  .pow(256)
  .minus(1)
  .toString(10)

export const DEFAULT_TIME_CONFIG = [
  new Date(2000, 1, 1, 0, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59, 59),
]

// 网络类型
export const NETWORK_TYPE = { 'ERC-20': 'ERC-20', 'TRC-20': 'TRC-20' }
