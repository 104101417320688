import { getLoginInfo } from '@/api/account'

export default {
  namespaced: true,
  state: {
    loginInfo: {},
    menuList: [],
  },
  mutations: {
    Update_LoginInfo(state, data) {
      state.loginInfo = data
    },
  },
  actions: {
    // 获取登录用户信息
    queryLoginInfo({ commit }) {
      return getLoginInfo().then((res) => {
        if (res.check()) {
          commit('Update_LoginInfo', res.data)
        }
        return res
      })
    },
  },
  getters: {},
}
