import Web3Connect from '../adapter'

export async function getTokenBalance(
  { tokenAddress, contractAddress },
  network
) {
  try {
    const web3Connect = new Web3Connect({
      chainId: network.chainId,
      network: network.addressType,
      rpcUrl: network.rpcUrl,
    })
    return await web3Connect.getTokenBalance(tokenAddress, contractAddress)
  } catch (error) {
    throw error
  }
}

// 获取授权状态
export async function getAllowance(
  { tokenAddress, contractAddress, from },
  network
) {
  try {
    const web3Connect = new Web3Connect({
      chainId: network.chainId,
      network: network.addressType,
      rpcUrl: network.rpcUrl,
    })
    return await web3Connect?.adapter?.getAllowance?.(
      tokenAddress,
      from,
      contractAddress
    )
  } catch (error) {
    throw error
  }
}
