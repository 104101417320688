import { getTokenBalance, getAllowance } from '@/utils/wallet/utils'
import { get, set } from 'lodash'

const defaultConnectInfo = {
  account: '', // 账号
  chainId: '', // 链id
  connected: false, // 是否已连接
  network: '', // 网络
  wallet: '', // 钱包类型
}

export default {
  namespaced: true,
  state: {
    web3Connect: null, // web3连接
    connectInfo: { ...defaultConnectInfo },
    balance: {}, // 余额集合
    balanceTime: {},
    allowance: {}, // 授权列表
  },
  mutations: {
    Update_balanceTime(state, data) {
      // 余额请求时间
      const { chainId, contractAddress, tokenAddress } = data.params
      set(
        state.balanceTime,
        [contractAddress, chainId, tokenAddress],
        data.time
      )
    },
    Update_balance(state, data) {
      // 余额
      const { chainId, contractAddress, tokenAddress } = data.params
      set(state.balance, [contractAddress, chainId, tokenAddress], data.balance)
    },
    Update_ConnectInfo(state, data) {
      state.connectInfo = {
        ...state.connectInfo,
        ...data,
      }
    },
    Reset_ConnectInfo(state, data) {
      state.connectInfo = { ...defaultConnectInfo }
    },
    Update_web3Connect(state, data) {
      // 更新web3方法
      state.web3Connect = data
    },
    Update_allowance(state, data) {
      // 更新授权
      const { chainId, contractAddress, tokenAddress, from } = data.params
      set(
        state.allowance,
        [from, contractAddress, chainId, tokenAddress],
        data.allowance
      )
    },
  },
  actions: {
    // 获取链上余额信息
    queryTokenBalance({ commit, state, rootState }, params) {
      const {
        contractAddress,
        chainId,
        tokenAddress,
        forceUpdate = false,
      } = params
      const lastQueryTime = get(
        state.balanceTime,
        [contractAddress, chainId, tokenAddress],
        0
      )
      if (+new Date() - lastQueryTime < 1000 * 60 && forceUpdate !== true) {
        // 忽略1分钟内的请求
        return get(state.balance, [contractAddress, chainId, tokenAddress])
      }
      // 更新请求token时间
      commit('Update_balanceTime', {
        params,
        time: +new Date(),
      })
      const network = rootState._config.allNetworks.find(
        (item) => item.chainId === chainId
      )
      return getTokenBalance(params, network).then((res) => {
        if (res) {
          commit('Update_balance', {
            params,
            balance: res,
          })
        } else {
          // 如果调取失败就重置
          commit('Update_balanceTime', {
            params,
            time: 0,
          })
        }
        return res
      })
    },
    // 授权
    queryAllowance({ commit, state, rootState }, params) {
      const { chainId, contractAddress, tokenAddress } = params
      const network = rootState._config.allNetworks.find(
        (item) => item.chainId === chainId
      )
      const from = rootState._web3.connectInfo?.account || ''
      const currentAllowance = get(
        state.allowance,
        [from, contractAddress, chainId, tokenAddress],
        0
      )
      if (currentAllowance > 0) {
        return currentAllowance
      }
      return getAllowance({ ...params, from }, network).then((res) => {
        if (res) {
          commit('Update_allowance', {
            params: { ...params, from },
            allowance: res,
          })
        }
        return res
      })
    },
  },
}
