import axios from 'axios'
import router from '@/router'
import { getDefaultLocal } from '@/i18n/i18n'
import { loadToken, downloadBlob, updateToken } from '@/utils/common'
import CheckAPI from './checkAPI'

const apiURL = process.env.VUE_APP_apiURL

// create an axios instance
const service = axios.create({
  baseURL: apiURL, // url = base url + request url
  // baseURL: 'http://backstage-b2b.ufcfan.org/',
  timeout: 60000, // request timeout
})
const headers = service.defaults.headers
headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// request interceptor
service.interceptors.request.use(
  (config) => {
    // 请求头设置
    config.headers['accessToken'] = loadToken()
    config.headers['lang'] = getDefaultLocal()
    config.toastErr = config.toastErr ?? true
    // 导出参数设置输出类型
    if (config.data?.export === true) {
      config.responseType = 'blob'
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const { data, config, headers, request } = response
    // 保存token
    if (headers.accesstoken) {
      updateToken(headers.accesstoken)
    }

    if (headers['content-disposition']) {
      const fileName = headers['content-disposition'].split('=')[1]
      const result = {
        fileName: decodeURIComponent(fileName),
        data: data,
      }
      downloadBlob(result.data, result.fileName) // 导出文件
      return result
    } else {
      const result = new CheckAPI(data)

      // 登录已过期
      const isExpired =
        result.code === '000109' ||
        result.code === '80060016' ||
        result.code === '000108'

      if (isExpired) {
        router.replace({
          name: 'Login',
        })
      }

      if (!result.check()) {
        if (config.toastErr && !isExpired) result.toast()
        return Promise.reject(data)
      } else {
        return result
      }
    }
  },
  (error) => {
    const { config, response } = error
    if (config.toastErr && config.url && response?.config) {
      const result = new CheckAPI(response.data)
      if (!result.check()) result.toast()
      return Promise.reject(result)
    }
    return Promise.reject(new CheckAPI(response?.data || {}))
  }
)

export default service
