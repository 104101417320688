import { createRouter, createWebHistory } from 'vue-router'
import { loadToken } from '@/utils/common'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to) => {
  const token = loadToken()
  if (to.name !== 'Login' && !token) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
      },
    }
  }
  // 返回 false 以取消导航
  return true
})
export default router
