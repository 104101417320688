import { getAllNetworks, getSymbolConfig } from '@/api/config'
import { groupBy } from 'lodash'

export default {
  namespaced: true,
  state: {
    allNetworks: [],
    allSymbols: [],
    combinedList: [], // 组合网络+币种列表
  },
  mutations: {
    Update_AllNetworks(state, data) {
      state.allNetworks = data
    },
    Update_AllSymbols(state, data) {
      state.allSymbols = data
    },
    Update_CombinedList(state, data) {
      state.combinedList = data
    },
  },
  actions: {
    // 获取所有网络配置
    queryAllNetworks({ commit }) {
      return getAllNetworks().then((res) => {
        const data = res.data
          .filter((item) => item.chainId !== '5')
          .map((item) => {
            // 格式化rpc地址
            const tronRpcUrl = item.testNet
              ? 'https://api.shasta.trongrid.io'
              : 'https://api.trongrid.io'

            return {
              ...item,
              rpcUrl:
                item.addressType === 'ETH'
                  ? `${process.env.VUE_APP_apiURL}/wallet/mng/infura/node/${item.chainId}`
                  : tronRpcUrl,
            }
          })
        commit('Update_AllNetworks', data)
        return {
          ...res,
          data,
        }
      })
    },
    // 获取币种配置
    queryAllSymbols({ commit }) {
      return getSymbolConfig({ bizType: '1' }).then((res) => {
        if (res.check()) {
          commit('Update_AllSymbols', res.data)
        }
        return res
      })
    },
  },
  getters: {
    // 获取网络列表
    getNetworkList(state) {
      let obj = {}
      state.allNetworks.filter((item) => {
        obj[item.chainId] = item.name
      })
      return obj
    },
    // 获取币种列表
    getSymbolList(state) {
      let obj = {}
      state.allSymbols.filter((item) => {
        if (!obj[item.symbol]) {
          obj[item.symbol] = item.symbol
        }
      })
      return obj
    },
    // 组合网络+币种列表
    getCombinedList(state) {
      const allSymbols = groupBy(state.allSymbols, 'chainId')
      return state.allNetworks.map((item) => {
        const {
          addressType,
          chainId,
          network,
          name,
          explorer,
          collectContractAddress,
        } = item
        const symbols = allSymbols[chainId] || []
        return {
          addressType,
          chainId,
          network,
          name,
          explorer,
          symbols,
          collectContractAddress,
        }
      })
    },
    // 网络基础信息
    getBrowserUrls(state) {
      const browserUrls = {}
      state.allNetworks.filter((item) => {
        let url = {}
        const base = item?.explorer || ''
        switch (item.addressType) {
          case 'ETH':
            url = {
              network: item.network,
              account: base + 'address/',
              contract: base + 'address/',
              txHash: base + 'tx/',
            }
            break
          case 'TRON':
            url = {
              network: item.network,
              account: base + '#/address/',
              contract: base + '#/contract/',
              txHash: base + '#/transaction/',
            }
            break
        }
        browserUrls[item.chainId] = url
      })
      return browserUrls
    },
  },
}
