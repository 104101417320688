import { nextTick, unref } from 'vue'
import { createI18n } from 'vue-i18n'
import zhHK from './zh-HK.json'
import enUS from './en-US.json'
import zhCN from './zh-CN.json'
import { localGet } from '@/utils/common'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import zhTw from 'element-plus/dist/locale/zh-tw.mjs'

const i18nMessage = {
  'zh-HK': zhHK,
  'zh-CN': zhCN,
  'en-US': enUS,
}

// 获取默认语言
export const getDefaultLocal = () => {
  let defaultLang = localGet('lang')
  if (!i18nMessage[defaultLang]) {
    const language = navigator.language.toLowerCase()
    if (language.indexOf('zh') !== -1) {
      if (language.indexOf('hk') !== -1 || language.indexOf('tw') !== -1) {
        defaultLang = 'zh-HK'
      } else {
        defaultLang = 'zh-CN'
      }
    }
  }
  return defaultLang || 'en-US'
}

const defaultLocal = 'zh-HK' // getDefaultLocal()

const i18n = setupI18n(defaultLocal)
export default i18n

export function setupI18n(locale) {
  const i18n = createI18n({
    legacy: false,
    messages: i18nMessage,
    fallbackLocale: 'zh-HK',
    warnHtmlMessage: false,
    locale,
  })
  const currentLocal = i18nMessage[locale] ? locale : 'en-US'
  setI18nLanguage(i18n, currentLocal)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./${locale}.json`
  )
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export function getI18nLocal(locale) {
  const localeJSON = {
    'zh-CN': zhCn,
    'en-US': en,
    'zh-HK': zhTw,
  }
  return localeJSON[unref(locale)] || localeJSON['en-US']
}
