import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router'
import store from './store'
import BigNumber from 'bignumber.js'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import mixin from './mixin.js'
import { v4 as guid } from 'uuid'

BigNumber.config({ EXPONENTIAL_AT: [-16, 26] })

const app = createApp(App)
app.use(i18n).use(store).use(router)
app.mixin(mixin)
app.mount('#app')

if (!window.crypto?.randomUUID) {
  window.crypto.randomUUID = guid
}
